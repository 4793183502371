import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from '../components/layout'
import baselogo from '../images/logo_base.png'

class PrivacyPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')  
    
    return (
      <Layout location={this.props.location} >
        <SEO title={siteTitle} keywords={[`artificial intelligence`,`machine learning`, `automation`, `rpa`, 'deep learning', 'ai']} />
        <div style={{ background: '#fff' }}>                             
          <div className="page_wrapper" style={{minHeight:'60vmin'}}>
            <h4 className='navbutton'><a href='/' >&#8249; Back </a></h4>
            <div style={{backgroundColor:"#909090"}}>
              <img className="legals_logo" src={baselogo} alt="" />
            </div>
            <h1 className="section-headline">Privacy Policy</h1>                                  
            <ul>
                <li>Thank you for visiting the TenTenths Digital website. We protect the privacy of our users and respect their personal information belongs to them. This policy outlines how use information collected through this website.</li>
                <li>The term "Personal Information" in this policy refers to any information from which a user’s identity can be reasonably required. We do not collect Personal Information about users who utilise this website.</li>
                <li>Users can use this website without providing any Personal Information or informing TenTenths Digital. Contact form, newsletter or feedback submissions do not require a user to enter any personal information. If a user makes contact, we will collect any identifying information provided, such as a name, email or phone number. Please do not give us other personal or sensitive information.</li>
                <li>This website is hosted in Australia and cached globally utilising secure facilities. To protect the privacy of personal information we collect, physical, technical and administrative safeguards are maintained.  Our employees are trained regarding the importance of confidentiality and maintaining the privacy and security of user information. Access to a user’s Personal Information is restricted to those employees who provide benefits or services to users. TenTenths Digital update and test our security technology on an ongoing basis.</li>
                <li>To improve a user’s experience, we use 'cookies'. Cookies are an industry standard and most major web sites use them. A cookie is a small amount of data that this website places on a user’s computer as a tool to remember any preferences changed in relation to our site. Refusing the use of cookies is possible by selecting the related browser settings. Please note that this will restrict the full functionality of this website.</li>
                <li>This website contains links to external websites. Please be aware that we are not responsible for the privacy policies and actions of other sites. A user is responsible for reviewing the privacy policy of other sites if they follow external links.</li>
                <li>This website uses Google Analytics, which transmits website traffic data to Google servers in the United States. Google Analytics does not identify individual users or associate a user’s IP address with any other data held by Google. TenTenths Digital use reports provided by Google Analytics to help understand website traffic and webpage usage. By using this website, a user consents to the processing of data about the user by Google in the manner described in Google's Privacy Policy and for the purposes set out above. A user can opt out of Google Analytics if the user disables or refuses the cookie, disable JavaScript, or use the opt-out service provided by Google- external site.</li>
                <li>TenTenths Digital utilises social media sites Facebook, Instagram, LinkedIn, Twitter and others. If a user chooses to interact with information from this website through these services, the user should review the privacy policy of that service. If a user is a member of a social media site which interfaces with this website, the interface may allow the social media site to connect visits to this site with other Personal Information.</li>
                <li>This website is bound by the provisions of the Privacy Act 1988 (Cth, including the Australian Privacy Principles.</li>
            </ul>
            <hr style={{border: '1px solid #505050', maxWidth:"80vw"}}/>
            
          </div>           
        </div>
      </Layout>
    )
  }
}

export default PrivacyPage

export const pageQuery = graphql`
  query PrivacyPageIndexQuery {
    site {
      siteMetadata {
        title
      }
    }    
  }
`
